import styled, { css } from 'styled-components';
import tc from 'tinycolor2';
import { TThemeVariables } from '../types';
import { Heading } from '../../Typography';
import { IImageWrapperProps } from '../props';

import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  getBoxShadow,
  px,
  styleComponent
} from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.Card);
const cardStyler = styleComponent(style);

const cardWrapperCss = (t: TThemeVariables) => css`
  background: ${t.wrapper.backgroundColor};
  box-shadow: ${getBoxShadow('large')};
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  ${(props: any) =>
    props.icon
      ? `
      display: grid;
      grid-template-columns: 3rem auto;
      grid-template-areas: "icon content" ". content" ". content"; 
    `
      : ``};
  & {
    .icon-container {
      grid-area: icon;
      margin-top: 0.25rem;
      margin-right: 1rem;

      @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
        margin-top: unset;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
`;

const wrapperThemeStyleCallback = (styles: any) => styles.Card.CardWrapper;

export const CardWrapper = styled.div([
  cardStyler(cardWrapperCss, wrapperThemeStyleCallback)
]);
CardWrapper.displayName = 'CardWrapper';

export const CardHeading = styled(Heading)`
  grid-area: heading;
`;
CardHeading.displayName = 'CardHeading';

const cardListCss = (t: TThemeVariables) => css`
  margin: 0 -${paddingHorizontal(1)} -${paddingVertical(1)} -${paddingHorizontal(1)};
  border-top: ${px(t.list.borderSize)} solid ${t.list.borderColor};
`;
const listThemeStyleCallback = (styles: any) => styles.Card.CardList;

export const CardList = styled.div([
  cardStyler(cardListCss, listThemeStyleCallback)
]);
CardList.displayName = 'CardList';

// Card Deck

const headingWrapperCss = (t: TThemeVariables) => css`
  margin: 0 1rem 1rem 0;

  @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
    margin: 0 1rem 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    margin: 0 1rem 0;
  }

  text-align: left;
`;

const headingWrapperThemeStyleCallback = (styles: any) =>
  styles.Card.CardDeck.HeadingWrapper;

export const HeadingWrapper = styled.div([
  cardStyler(headingWrapperCss, headingWrapperThemeStyleCallback)
]);
HeadingWrapper.displayName = 'HeadingWrapper';

const buttonWrapperCss = (t: TThemeVariables) => css`
  flex: 0 1 100%;
  text-align: center;
`;

const buttonWrapperThemeStyleCallback = (styles: any) =>
  styles.Card.CardDeck.ButtonWrapper;

export const ButtonWrapper = styled.div([
  cardStyler(buttonWrapperCss, buttonWrapperThemeStyleCallback)
]);
ButtonWrapper.displayName = 'ButtonWrapper';

const cardsWrapperCss = (t: TThemeVariables) => css`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  justify-content: center;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
    grid-template-columns: repeat(4, minmax(2.5rem, 15.5rem));
    padding: 4rem 0 3rem;
  }
  & > * {
    min-width: 5rem;
    max-width: 31rem;
    margin: 0 1rem 1rem 0;

    @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
      margin: 0 1rem 2rem;
      :nth-child(odd):not(:last-child) {
        grid-column: 1 / span 2;
      }
      :nth-child(even) {
        grid-column: 3 / span 2;
      }

      :nth-child(odd):last-child {
        grid-column: 2 / span 2;
      }
    }
  }
`;

const cardsWrapperThemeStyleCallback = (styles: any) =>
  styles.Card.CardDeck.CardsWrapper;

export const CardsWrapper = styled.div([
  cardStyler(cardsWrapperCss, cardsWrapperThemeStyleCallback)
]);
CardsWrapper.displayName = 'CardsWrapper';

const deckWrapperCss = (t: TThemeVariables) => css`
  position: relative;
  max-width: 90rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  justify-content: start;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  padding: 3rem 1rem 2rem;
`;

const deckThemeStyleCallback = (styles: any) => styles.Card.CardDeck.Deck;

export const Deck = styled.div([
  cardStyler(deckWrapperCss, deckThemeStyleCallback)
]);
Deck.displayName = 'CardDeck';
