import React, { Dispatch, SetStateAction } from 'react';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel
} from '@microsoft/signalr';
import {
  IMessageProps,
  IProgressContextProps,
  ITaskLogProgressContextProps
} from './props';
import { Row } from '../Row';
import { GaugeRadialChart } from '../GaugeRadialChart';
import { ActivityIndicator } from '../ActivityIndicator';

interface ProgressState {
  name: string;
  errors: string | null;
  total: number | null;
  items: number | null;
  subTasks: ProgressState[];
}

const TaskLogProgressContext = React.createContext<
  ITaskLogProgressContextProps
>({ apiUri: '', accessTokenFactory: () => '', enforceLongPolling: false });

export const TaskLogProgressProvider: React.FC<IProgressContextProps> = ({
  apiUri,
  accessTokenFactory,
  enforceLongPolling,
  children
}) => (
  <TaskLogProgressContext.Provider
    value={{ apiUri, accessTokenFactory, enforceLongPolling }}
  >
    {children}
  </TaskLogProgressContext.Provider>
);

const mapStatus = (msg: IMessageProps): ProgressState => ({
  name: msg.name,
  items: msg.items,
  total: msg.total,
  errors: msg.errors,
  subTasks:
    msg.subTasks && Array.isArray(msg.subTasks)
      ? msg.subTasks.map(mapStatus)
      : []
});

const updateProgress = (setStatus: Dispatch<SetStateAction<ProgressState>>) => (
  msg: IMessageProps
) => {
  if (!msg) {
    return;
  }
  const status = mapStatus(msg);
  setStatus(status);
};

const monitorProgress = (
  jobId: string,
  hub: HubConnection,
  setStatus: Dispatch<SetStateAction<ProgressState>>,
  onComplete: (msg: IMessageProps) => {}
) => async () => {
  const update = updateProgress(setStatus);
  hub.on('TaskProgressed', update);
  hub.on('TaskComplete', onComplete);
  update(await hub.invoke('WatchTask', jobId));
};

const startMonitoring = (
  taskLogApiUri: string,
  accessTokenFactory: () => string,
  enforceLongPolling: boolean,
  jobId: string,
  setStatus: Dispatch<SetStateAction<ProgressState>>,
  onComplete: (msg: IMessageProps) => {}
) => () => {
  const start = async (hub: HubConnection) => {
    const monitor = monitorProgress(jobId, hub, setStatus, onComplete);
    await hub.start();
    monitor();
    hub.onreconnected(monitor);
  };

  const options: IHttpConnectionOptions = { accessTokenFactory };
  if (enforceLongPolling) {
    options.transport = HttpTransportType.LongPolling;
  }

  const connection = new HubConnectionBuilder()
    .withUrl(taskLogApiUri + '/task-progress', options)
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

  start(connection);

  return () => {
    if (connection) {
      connection.stop();
    }
  };
};

export default ({
  jobId,
  onComplete
}: {
  jobId: string;
  onComplete: (msg: IMessageProps) => {};
}) => {
  const { apiUri, accessTokenFactory, enforceLongPolling } = React.useContext(
    TaskLogProgressContext
  );
  const [status, setStatus] = React.useState<ProgressState>({
    name: 'Waiting for updates...',
    items: 0,
    total: 0,
    errors: null,
    subTasks: []
  });

  React.useEffect(
    startMonitoring(
      apiUri,
      accessTokenFactory,
      enforceLongPolling,
      jobId,
      setStatus,
      onComplete
    ),
    []
  );

  const renderSubTasks = (task: ProgressState, depth: number = 0) =>
    task.subTasks && task.subTasks.length > 0 ? (
      task.subTasks.map(subTask => renderTask(subTask, depth + 1))
    ) : (
      <></>
    );

  const renderTask = (task: ProgressState, depth: number): JSX.Element => {
    if (!task) {
      return <></>;
    }
    const indent = depth * 8;
    return (
      <div key={task.name} style={{ marginLeft: `${indent}px` }}>
        {/* Render name */}
        <p>
          {task.name}
          {task.items !== null && task.total !== null && task.total > 1
            ? ` ${task.items} / ${task.total}`
            : ''}
        </p>
        {/* Render error message */}
        {task.errors && task.errors.trim() ? (
          <p style={{ color: 'red' }}>{task.errors}</p>
        ) : (
          <></>
        )}
        {/* Recursively render subtasks */}
        {renderSubTasks(task, depth + 1)}
      </div>
    );
  };

  return (
    <Row hAlign="center" spaceBetween={3} vAlign="center">
      {status.items && status.total && status.total > 0 ? (
        <GaugeRadialChart
          progress={String((status.items / status.total) * 100)}
        >
          <p>
            {status.items || 0}/{status.total || 0}
          </p>
        </GaugeRadialChart>
      ) : (
        <ActivityIndicator />
      )}
      <div>
        <p>{status.name}</p>
        {renderSubTasks(status)}
      </div>
    </Row>
  );
};
