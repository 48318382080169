import React from 'react';
import { createStore } from 'redux';
import { render } from 'react-dom';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { GatewayProvider } from 'react-gateway';
import { Provider } from 'react-redux';
import { injectGlobal } from 'styled-components';
import { utils } from '@parmenion/library';

import injectGlobalStyles from './global-styles';

import reducer from './reducer';

import App from './app';
import Home from './home';
import Guide from './guide';
import Usage from './usage';
import QuickStart from './quick-start';
import Page from './page';
import Components from './components';
import ComponentDetails from './components/details';
import Article from './components/article';

const { buildTheme } = utils;
const GlobalStyles = injectGlobalStyles(buildTheme({}));

const store = createStore(reducer);

render(
  <GatewayProvider>
    <>
      <GlobalStyles />
      <Provider store={store}>
        <Router history={browserHistory}>
          <Route path="/" component={App}>
            <IndexRoute component={Home} />
            <Route path="guide" component={Guide} />
            <Route path="usage" component={Usage} />
            <Route path="quick-start" component={QuickStart} />
            <Route path="page">
              <IndexRoute component={Page} />
              <Route path=":section/:article" component={Article} />
            </Route>
            <Route path="components">
              <IndexRoute component={Components} />
              <Route path=":module/:component" component={ComponentDetails} />
            </Route>
          </Route>
        </Router>
      </Provider>
    </>
  </GatewayProvider>,
  document.getElementById('root')
);
