import React from 'react';
import { zip } from 'lodash/fp';

import {
  Separated,
  Lead,
  Title,
  Content,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Nav,
  Button,
  theme
} from '@parmenion/library';

import {
  PageHeader,
  Horizontal,
  Stacked,
  Size,
  DebugBox,
  SideBar,
  Box,
  Wrapper,
  Heading
} from '../ui';

import { Link } from 'react-router';
import { Gateway } from 'react-gateway';
import styled from 'styled-components';
import { withPropsOnChange } from 'recompose';
import components from './all-components';
import { Playground } from './playground';
import { find } from 'rambda';

const componentList = components.modules[0].components;

const withComponent = withPropsOnChange(['router'], props => {
  return (
    props != undefined && {
      component: find(
        component => component.key === props.router.params.component,
        componentList
      )
    }
  );
});

const Page = props => (
  <Wrapper stacked>
    <Gateway into="header">
      <PageHeader header={props.header} lead={props.lead} />
    </Gateway>
    <Box stacked spaceBetween={2}>
      {props.children}
    </Box>
  </Wrapper>
);

export default withComponent(({ component }) => {
  console.log(component);
  return (
    <Page header={component.name}>
      <Separated>
        {component.meta.description != null && (
          <div>
            <Heading>Description</Heading>
            <Lead>{component.meta.description}</Lead>
          </div>
        )}
        {component.meta.tags != null && (
          <div>
            <Heading>Tags</Heading>
            <Lead>{component.meta.tags.split(' ').join(', ')}</Lead>
          </div>
        )}
        <div>
          <Heading>Examples</Heading>
          {component.meta != null &&
            component.meta.examples != null &&
            zip(component.meta.examples, component.meta.titles).map(
              ([example, title], index) => {
                const shouldCompile = !example.includes('<Progress');
                return (
                  <div key={index}>
                    <Lead>{title}</Lead>
                    {component.meta.images && component.meta.images[index] && (
                      <img
                        src={component.meta.images[index].url}
                        alt={
                          component.meta.images[index].alt || 'Component image'
                        }
                      />
                    )}
                    {shouldCompile ? (
                      <Playground
                        name={component.name}
                        themeVariables={component.themeVariables}
                        source={example}
                      />
                    ) : (
                      <div>
                        <h2>Code example:</h2>
                        <pre>{example}</pre>
                      </div>
                    )}
                  </div>
                );
              }
            )}
        </div>

        {component.propTypes != null && (
          <ComponentProps propTypes={component.propTypes} />
        )}
      </Separated>
    </Page>
  );
});

const ComponentProps = ({ propTypes }) => (
  <Box stacked>
    <Heading>Props</Heading>

    <Table>
      <TableRow>
        <TableHeader style={{ width: 200 }}>Name</TableHeader>
        <TableHeader style={{ width: 300 }}>Type</TableHeader>
      </TableRow>
      {propTypes.map(propType => (
        <TableRow key={propType.name}>
          <TableCell>{propType.name}</TableCell>
          <TableCell>
            <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
              {propType.type}
            </pre>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </Box>
);
