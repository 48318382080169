const primary = '#91c95b';
const secondary = '#002664';
const auxillary = '#1a9ce3';
const good = '#91c95b';
const bad = '#B10E1E';
const informational = '#5bc0de';
const warning = '#f0ad4e';
const lightest = '#fff';
const darkest = '#000';
const transparent = 'transparent';
const danger = '#f7949e';
const warningLight = '#fceedb';
const informationLight = '#daf1f8';
const successLight = '#deefce';

export const colors = {
  primary,
  secondary,
  auxillary,
  success: good,
  error: bad,
  informational,
  warning,
  lightest,
  darkest,
  transparent,
  danger,
  warningLight,
  informationLight,
  successLight,
  neutrals: {
    darkest,
    darker: '#222',
    dark: '#333',
    normal: '#777',
    light: '#ccc',
    lighter: '#e9e9e9',
    lightest: '#f9f9f9'
  },
  active: {
    primary: {
      background: primary,
      color: lightest
    },
    secondary: {
      background: secondary,
      color: lightest
    }
  }
};

export type IColorsType = typeof colors;
