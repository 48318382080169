import React from 'react';
import { Box } from '../core';

import { Menu, MenuItem } from '../Menu';
import {
  CardWrapper,
  CardHeading,
  CardList,
  Deck,
  HeadingWrapper,
  ButtonWrapper,
  CardsWrapper
} from './core';

export const CardBase = (props: any) => (
  <CardWrapper {...props}>
    {props.icon && <div className="icon-container">{props.icon}</div>}
    <Box stacked>
      {props.heading && [<CardHeading key={0}>{props.heading}</CardHeading>]}
      {props.children}
    </Box>
  </CardWrapper>
);

export const CardListBase = (props: any) => (
  <CardList>
    <Menu {...props} />
  </CardList>
);

export const CardListItemBase = (props: any) => {
  return (
    <MenuItem {...props} as={props.Container} hasArrow={props.hasArrow}>
      {props.label}
    </MenuItem>
  );
};

export const CardDeckBase = (props: any) => {
  return (
    <Deck {...props}>
      {props.background}
      {props.heading && <HeadingWrapper>{props.heading}</HeadingWrapper>}
      <CardsWrapper>{props.children}</CardsWrapper>
      {props.button && <ButtonWrapper>{props.button}</ButtonWrapper>}
    </Deck>
  );
};
