import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '../../';
import {
  wrapperStyle,
  iconStyle,
  containerStyle,
  titleStyle,
  subtitleStyle
} from './style';

const Wrapper = styled.div([wrapperStyle]);
const IconWrapper = styled.div([iconStyle]);
const Container = styled.div([containerStyle]);
const Title = styled.h4([titleStyle]);
const SubTitle = styled.div([subtitleStyle]);

const iconMap = {
  success: () => <Icon name="check" />,
  danger: () => <Icon name="infoCircle" />,
  warning: () => <Icon name="infoCircle" />,
  info: () => <Icon name="infoCircle" />
};

const IconBase = ({ type = 'info' }) => {
  const ThisIcon = iconMap[type];
  return (
    <IconWrapper>
      <ThisIcon />
    </IconWrapper>
  );
};

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const {
      title,
      subtitle,
      type,
      withIcon,
      isDismissable,
      ...rest
    } = this.props;
    const { visible } = this.state;

    if (isDismissable && !visible) return null;

    return (
      <Wrapper styleProps={{ type }} {...rest}>
        <Container>
          {title && (
            <>
              {withIcon && <IconBase type={type} />}
              <Title styleProps={{ type }}>{title}</Title>
            </>
          )}
          {subtitle && <SubTitle styleProps={{ type }}>{subtitle}</SubTitle>}
          {isDismissable && (
            <SubTitle onClick={this.handleDismiss} styleProps={{ type }}>
              <Icon name="close" />
            </SubTitle>
          )}
        </Container>
      </Wrapper>
    );
  }
}

export default Notification;
