import { IColorsType } from '../core/colors';

export const notification = (colors: IColorsType) => ({
  successColor: colors.darkest,
  infoColor: colors.darkest,
  warningColor: colors.darkest,
  dangerColor: colors.darkest,
  successBgColor: colors.successLight,
  infoBgColor: colors.informationLight,
  warningBgColor: colors.warningLight,
  dangerBgColor: colors.danger
});
