import React from 'react';
import styled, { withTheme } from 'styled-components';
import { px } from 'utils';
import { Box } from '../../../core/';
import {
  ErrorWrapper,
  ErrorText,
  HelpText,
  Label,
  WarningWrapper,
  WarningText
} from '../core';

interface IOptionalProps {
  showWhen: boolean;
}

const Optional = styled.span`
  display: ${(props: IOptionalProps) =>
    props.showWhen ? 'inline-block' : 'none'};
`;
Optional.displayName = 'Optional';

const VerticalWrapper = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: ${props => px(props.theme.layout.spacing[1])};
  }
  > :last-child {
    margin-bottom: 0;
  }
`);

export const VerticalFormFieldBase: React.StatelessComponent<any> = (
  props: any
) => {
  const {
    children,
    label,
    underHelpText,
    showErrorAbove,
    error,
    helpText,
    htmlFor,
    id,
    warning,
    ...rest
  } = props;
  return (
    <ErrorWrapper hasError={error != null} {...rest}>
      <WarningWrapper hasWarning={!error && !!warning} {...rest}>
        <VerticalWrapper key="container">
          <Optional key="label" showWhen={label != null}>
            <Label htmlFor={htmlFor} id={id}>
              {label}
            </Label>
          </Optional>
          <Optional key="helpText" showWhen={helpText != null}>
            {helpText != null && <HelpText>{helpText}</HelpText>}
          </Optional>

          <Optional
            key="showErrorAbove"
            showWhen={showErrorAbove && error != null}
          >
            {showErrorAbove && error != null && <ErrorText>{error}</ErrorText>}
          </Optional>

          <Box stacked key="content">
            {React.Children.only(children)}
          </Box>

          <Optional key="underHelpText" showWhen={underHelpText != null}>
            {underHelpText != null && <HelpText>{underHelpText}</HelpText>}
          </Optional>

          <Optional
            key="showErrorBelow"
            showWhen={!showErrorAbove && error != null}
          >
            {!showErrorAbove && error != null && <ErrorText>{error}</ErrorText>}
          </Optional>
          <Optional key="warning" showWhen={!error && !!warning}>
            {!error && !!warning && <WarningText>{warning}</WarningText>}
          </Optional>
        </VerticalWrapper>
      </WarningWrapper>
    </ErrorWrapper>
  );
};
VerticalFormFieldBase.displayName = 'VerticalFormFieldBase';

const WithoutLabel = ({ children, error, warning, ...rest }: any) => {
  return (
    <ErrorWrapper hasError={error != null} {...rest}>
      <WarningWrapper hasWarning={!error && !!warning} {...rest}>
        <Box stacked>
          <Box horizontal>{React.Children.only(children)}</Box>
          {error != null && <ErrorText>{error}</ErrorText>}
        </Box>
      </WarningWrapper>
    </ErrorWrapper>
  );
};
export const HorizontalFormFieldBase: React.StatelessComponent<any> = (
  props: any
) => {
  const {
    children,
    label,
    error,
    warning,
    helpText,
    htmlFor,
    id,
    ...rest
  } = props;
  if (!label) return <WithoutLabel {...props} />;
  return (
    <Box horizontal {...rest}>
      <Box w={[1, 0.25]}>
        <ErrorWrapper hasError={error != null}>
          <WarningWrapper hasWarning={!error && !!warning}>
            <Label htmlFor={htmlFor} id={id}>
              {label}
            </Label>
          </WarningWrapper>
        </ErrorWrapper>
      </Box>
      <Box vAlign="center" stacked w={[1, 0.75]}>
        {React.Children.only(children)}

        {helpText != null && <HelpText>{helpText}</HelpText>}

        {error != null && <ErrorText>{error}</ErrorText>}

        {!error && !!warning && <WarningText>{warning}</WarningText>}
      </Box>
    </Box>
  );
};

HorizontalFormFieldBase.displayName = 'HorizontalFormFieldBase';

export const FormFieldBase: React.StatelessComponent<any> = (props: any) => {
  const {
    children,
    label,
    error,
    warning,
    helpText,
    htmlFor,
    id,
    ...rest
  } = props;
  if (!label) return <WithoutLabel {...props} />;
  return (
    <Box horizontal {...rest}>
      <Box w={[1, 0.25]}>
        <ErrorWrapper hasError={error != null}>
          <WarningWrapper hasWarning={!error && !!warning}>
            <Label htmlFor={htmlFor} id={id}>
              {label}
            </Label>
          </WarningWrapper>
        </ErrorWrapper>
      </Box>
      <Box vAlign="center" stacked w={[1, 0.75]}>
        {React.Children.only(children)}

        {helpText != null && <HelpText>{helpText}</HelpText>}

        {error != null && <ErrorText>{error}</ErrorText>}

        {!error && !!warning && <WarningText>{warning}</WarningText>}
      </Box>
    </Box>
  );
};

FormFieldBase.displayName = 'FormFieldBase';
