import { component } from 'builder';
import { IActivityIndicatorProps } from './props';
import Base from './base';

export const ActivityIndicator = component<IActivityIndicatorProps>({
  name: 'ActivityIndicator',
  base: Base,
  props: {
    isInverted: false,
    isCentered: false
  }
});
