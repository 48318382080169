import React from 'react';
import { uniq, compose, split, reduce, map, get, set } from 'lodash/fp';
import * as ParmenionLibrary from '@parmenion/library';
import styled, { css, keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import memoizee from 'memoizee';
import { counties as Counties } from '../data/counties';
import * as ui from '../ui';
import { pick } from 'rambda';

import { styles } from './styles';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/jsx/jsx';
import { CodeReader } from './code-reader';

import './playground.css';

const { Button, ThemeProvider, theme } = ParmenionLibrary;
const { buildTheme } = ParmenionLibrary.utils;
const defaultTheme = theme;

const canSwap = value => {
  return typeof value === 'string' ? /^\w+\.\w+/.test(value) : false;
};

function extend(output, str, value) {
  var items = str.split('.');
  var ref = output;

  //  loop through all nodes, except the last one
  for (var i = 0; i < items.length - 1; i++) {
    if (ref[items[i]] === 'undefined') ref[items[i]] = {}; // create a new element inside the reference
    ref = ref[items[i]]; // shift the reference to the newly created object
  }

  ref[items[items.length - 1]] = value; // apply the final value

  return output; // return the full object
}

const walkComponentThemeNodes = (theme, paths = []) => {
  if (typeof theme !== 'object') {
    return canSwap(theme) ? paths.push(theme) : null;
  }
  const keys = Object.keys(theme);
  while (keys.length > 0) {
    const key = keys.pop();
    walkComponentThemeNodes(theme[key], paths);
  }

  return uniq(paths);
};

const generateComponentThemeFromPaths = (defaultTheme, paths) => {
  let result = {};
  map(path => {
    const newValue = get(path, defaultTheme);
    result = set(path, newValue, result);
  }, paths);

  return result;
};

const THEME_NODES_TO_SHOW = ['colors', 'layout', 'bounds', 'decoration'];

const evalStyled = Object.create(null);
Object.defineProperty(evalStyled, '__esModule', { value: true });
evalStyled.default = styled;
evalStyled.css = css;
evalStyled.keyframes = keyframes;

const EVAL_AVAILABLE_MODULES = {
  '@parmenion/library': ParmenionLibrary,
  'styled-components': evalStyled,
  console: console,
  './counties': Counties,
  playground: ui,
  'js-cookie': Cookies
};

// memoized source compiler so we don't compile the source code every time if it hasn't changed
const compileSource = memoizee(source =>
  window.Babel.transform(source, {
    ast: false,
    retainLines: true,
    babelrc: false,
    presets: ['es2015', 'react']
  })
);

export class Playground extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.update(props);
  }

  update(props) {
    let theme = pick(THEME_NODES_TO_SHOW, buildTheme({}));
    if (
      this.props.themeVariables.variables &&
      this.props.themeVariables.variables.length > 0
    ) {
      theme = generateComponentThemeFromPaths(
        defaultTheme,
        this.props.themeVariables.variables
      );
    }

    return {
      compileException: null,
      source: props.source,
      playgroundState: {},
      theme: theme || {},
      activeTabs: {
        source: false,
        theme: false
      }
    };
  }

  tabOnClick = name => e => {
    e.preventDefault();
    this.setState({
      activeTabs: {
        ...this.state.activeTabs,
        ...{ [name]: !this.state.activeTabs[name] }
      }
    });
  };

  componentDidUpdate(nextProps) {
    return this.update(nextProps);
  }

  componentDidMount() {
    this.gen(this.state, true);
  }

  gen(_state, isInitialRender = false) {
    let out = null;

    let initialState = null;

    try {
      out = compileSource(_state.source);

      window.React = React;

      const require = module => {
        if (EVAL_AVAILABLE_MODULES[module] == null) {
          throw new Error(`Module "${module}" is not available in this sandbox.
Available modules are: ${Object.keys(EVAL_AVAILABLE_MODULES).join(', ')}`);
        }

        return EVAL_AVAILABLE_MODULES[module];
      };

      const state = _state.playgroundState;
      const setState = newState =>
        this.setState({
          playgroundState: {
            ...this.state.playgroundState,
            ...newState
          }
        });

      const result = eval(out.code);

      this.setState({
        renderElement: result,
        compileException: null
      });
    } catch (ex) {
      if (initialState != null && isInitialRender) {
        // try rendering again with the initialstate and see if there are any errors...
      } else {
        this.setState({ compileException: ex });
      }
    }

    if (initialState != null && isInitialRender) {
      // initialState was set in the source
      this.setState({ playgroundState: initialState });
    }
  }

  handleUpdateSource = (editor, data, newSource) => {
    this.setState({ source: newSource });
  };

  handleUpdateTheme = (editor, data, newTheme) => {
    this.setState({
      theme: JSON.parse(newTheme)
    });
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (
      prevState.source !== this.state.source ||
      prevState.theme !== this.state.theme ||
      prevState.playgroundState !== this.state.playgroundState
    ) {
      this.gen(this.state);
    }
    return null;
  }

  render() {
    const {
      compileException,
      source,
      renderElement,
      theme,
      activeTabs
    } = this.state;

    const editorOptions = {
      lineNumbers: true,
      lineWrapping: true,
      mode: 'jsx'
    };

    const themeEditorOptions = {
      lineNumbers: true,
      lineWrapping: true,
      mode: { name: 'javascript', json: true }
    };

    const componentThemeEditorOptions = {
      lineNumbers: true,
      lineWrapping: true,
      readOnly: true,
      mode: { name: 'javascript', json: true }
    };

    const tabStyle = activeTab =>
      Object.assign({}, styles.tab, activeTabs[activeTab] && styles.tabActive);

    return (
      <div style={styles.container}>
        <div style={styles.preview}>
          {compileException != null && renderElement == null && (
            <div style={styles.redBox}>{compileException.message}</div>
          )}

          {renderElement != null && (
            <div style={styles.renderFrame}>
              <div
                style={{
                  ...styles.renderFrameInner,
                  ...(compileException != null &&
                    styles.renderFrameInnerInvalid)
                }}
              >
                <ThemeProvider theme={buildTheme(theme)}>
                  {renderElement}
                </ThemeProvider>
              </div>
            </div>
          )}
        </div>

        <div style={styles.tabs}>
          <a
            href="#"
            onClick={this.tabOnClick('source')}
            style={tabStyle('source')}
          >
            View Source
          </a>
          <a
            href="#"
            onClick={this.tabOnClick('theme')}
            style={tabStyle('theme')}
          >
            View Theme
          </a>
          {defaultTheme.components[this.props.name] && (
            <a
              href="#"
              onClick={this.tabOnClick('componentTheme')}
              style={tabStyle('componentTheme')}
            >
              View Component Theme
            </a>
          )}
        </div>

        <CodeReader
          title="Code"
          isOpen={activeTabs.source}
          value={source}
          onChange={this.handleUpdateSource}
          options={editorOptions}
        />
        <CodeReader
          title="Theme"
          isOpen={activeTabs.theme}
          value={JSON.stringify(theme, null, '\t')}
          onChange={this.handleUpdateTheme}
          options={themeEditorOptions}
        />

        {defaultTheme.components[this.props.name] && (
          <CodeReader
            title="Component Theme"
            isOpen={activeTabs.componentTheme}
            value={this.props.themeVariables.source}
            options={componentThemeEditorOptions}
          />
        )}

        {compileException != null && renderElement != null && (
          <div style={styles.redBox}>{compileException.message}</div>
        )}
      </div>
    );
  }
}
