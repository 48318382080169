import React from 'react';
import { component } from 'builder';
import { IIconProps, ICustomIconProps } from './props';
import { IconBase, iconFactoryBase } from './base';

export * from './props';

const iconSpec = {
  name: 'Icon',
  props: {
    size: 1,
    name: null,
    icon: null,
    color: null,
    stroke: null,
    strokeWidth: 0,
    strokeLineJoin: null,
    padding: null
  }
};

export const Icon = component<IIconProps>({
  ...iconSpec,
  base: IconBase
});

type IconMapType = { [name: string]: React.ReactNode };

export const iconFactory = <TIconMap extends IconMapType>(iconMap: TIconMap) =>
  component<ICustomIconProps<TIconMap>>({
    ...iconSpec,
    base: iconFactoryBase(iconMap)
  });
