import { accordion } from './components/accordion';
import {
  baseButton,
  primaryButton,
  secondaryButton,
  successButton,
  errorButton,
  infoButton,
  warningButton,
  transparentButton
} from './components/buttons';

import { modal } from './components/modal';
import { menu } from './components/menu';
import { card } from './components/card';
import { field } from './components/forms';
import { inputText } from './components/input-text';
import { inputGroup } from './components/input-group';
import { textArea } from './components/text-area';
import { radioCheckboxButton } from './components/radio-checkbox-button';
import { checkbox } from './components/checkbox';
import { select } from './components/select';
import { nativeSelect } from './components/native-select';
import { content } from './components/content';
import { details } from './components/details';
import { table } from './components/table';
import { toolTip } from './components/tool-tip';
import { tag } from './components/tag';
import { activityIndicator } from './components/activity-indicator';
import { activityIndicatorWithOverlay } from './components/activity-indicator-with-overlay';
import { nav } from './components/nav';
import { separated } from './components/separated';
import { bar } from './components/bar';
import { fileUpload } from './components/file-upload';
import { breadcrumb } from './components/breadcrumb';
import { datePickerDropdown } from './components/date-picker-drop-down';
import { colors } from './core/colors';
import * as icons from './assets/icons';
import { typography } from './core/typography';
import { layout } from './core/layout';
import { bounds } from './core/bounds';
import { containers } from './core/containers';
import { breakpoints } from './core/breakpoints';
import { decoration } from './core/decoration';
import { styles } from './core/styles';
import { tabs } from './components/tabs';
import { stepper } from './components/stepper';
import { fuzzySearch } from './components/fuzzy-search';
import { gaugeChart } from './components/gauge-chart';
import { gaugeRadialChart } from './components/gauge-radial-chart';
import { notification } from './components/notification';
import { showHide } from './components/show-hide';
import { stepProgressBar } from './components/step-progress-bar';
import { detailList } from './components/detail-list';
import { pattern } from './components/pattern';
import { link } from './components/link';
import { ActivityIndicator } from 'components';

interface BuildVariables {
  typography: typeof typography;
  layout: typeof layout;
  bounds: typeof bounds;
  colors: typeof colors;
  decoration: typeof decoration;
  icons: typeof icons;
  breakpoints: typeof breakpoints;
  containers: typeof containers;
  styles: typeof styles;
}

export function build(variables: BuildVariables) {
  const {
    typography,
    layout,
    bounds,
    colors,
    decoration,
    icons,
    breakpoints,
    containers,
    styles
  } = variables;
  return {
    breakpoints,
    colors,
    typography,
    layout,
    bounds,
    containers,
    decoration,
    icons,
    components: {
      Accordion: accordion(colors, bounds),
      Bar: bar(colors, bounds),
      Breadcrumb: breadcrumb(colors, bounds),
      Button: baseButton(colors, bounds),
      Card: card(colors, bounds),
      Checkbox: checkbox(colors, layout, bounds),
      Content: content(colors),
      DatePickerDropdown: datePickerDropdown(layout),
      Details: details(colors, bounds),
      ErrorButton: errorButton(colors),
      Field: field(colors, bounds),
      FileUpload: fileUpload(colors, bounds),
      InputText: inputText(colors, bounds, containers),
      InputGroup: inputGroup(colors, bounds),
      InfoButton: infoButton(colors),
      Modal: modal(colors, bounds),
      Menu: menu(colors, bounds),
      NativeSelect: nativeSelect(colors, bounds),
      Nav: nav(colors, bounds),
      Pattern: pattern(colors, bounds),
      PrimaryButton: primaryButton(colors),
      RadioCheckboxButton: radioCheckboxButton(colors, layout, bounds),
      SecondaryButton: secondaryButton(colors),
      Select: select(colors, bounds, containers, layout),
      Separated: separated(colors, bounds),
      ActivityIndicator: activityIndicator(colors),
      ActivityIndicatorWithOverlay: activityIndicatorWithOverlay(colors),
      Stepper: stepper(colors, bounds, containers),
      SuccessButton: successButton(colors),
      Table: table(colors, bounds),
      Tabs: tabs(colors, bounds),
      Tag: tag(colors, bounds),
      TextArea: textArea(colors, bounds),
      ToolTip: toolTip(colors, bounds),
      TransparentButton: transparentButton(colors),
      WarningButton: warningButton(colors),
      FuzzySearch: fuzzySearch(colors, bounds),
      GaugeChart: gaugeChart(colors),
      GaugeRadialChart: gaugeRadialChart(colors),
      Notification: notification(colors),
      ShowHide: showHide(colors),
      StepProgressBar: stepProgressBar(colors),
      DetailList: detailList(colors),
      Link: link(colors)
    },
    styles
  };
}
