import { css } from 'styled-components';
import { selectComponentThemeVariablesFor } from 'utils';

const style = selectComponentThemeVariablesFor(
  theme => theme.ActivityIndicatorWithOverlay
);

export const overlayStyle = style(
  (t, { isInverted, isFull }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${isInverted ? t.inverted.backgroundColor : t.backgroundColor};
    width: 100%;
    height: 100%;
    opacity: ${isInverted ? t.inverted.opacity : t.opacity};
    z-index: 10;
  `
);
