import { component } from 'builder';
import { default as TaskLogProgress, TaskLogProgressProvider } from './base';
import { IProgressContextProps } from './props';
import { ThemeProvider, theme as defaultTheme } from '../../';
import ReactDOM from 'react-dom';
import React from 'react';

export const ProgressProvider = component<IProgressContextProps>({
  name: 'ProgressProvider',
  base: TaskLogProgressProvider,
  props: {
    apiUri: null,
    accessTokenFactory: null,
    children: null,
    enforceLongPolling: null
  }
});

export const Progress = component<{
  jobId: string;
  onComplete: () => {};
}>({
  name: 'Progress',
  base: TaskLogProgress,
  props: {
    jobId: null,
    onComplete: null
  }
});

export const addProgressComponent = (
  rootElement: HTMLDivElement,
  theme: any = {},
  taskLogApiUri: string,
  accessTokenFactory: () => string,
  enforceLongPolling: boolean,
  jobId: string,
  onComplete: () => {}
) => {
  const host = window as any;

  if (!host.progressComponent) {
    ReactDOM.render(
      <ThemeProvider
        theme={{
          ...defaultTheme,
          ...theme
        }}
      >
        <ProgressProvider
          apiUri={taskLogApiUri}
          accessTokenFactory={accessTokenFactory}
          enforceLongPolling={enforceLongPolling}
        >
          <Progress
            jobId={jobId}
            onComplete={onComplete}
            ref={c => {
              host.progressComponent = c;
            }}
          />
        </ProgressProvider>
      </ThemeProvider>,
      rootElement
    );
  }

  return host.progressComponent;
};
