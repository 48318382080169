import { component } from 'builder';
import { IFormFieldProps } from './props';
import { FormFieldBase } from './base/field';

export const FormField = component<IFormFieldProps>({
  name: 'FormField',
  base: FormFieldBase,
  props: {
    hasError: false,
    hasWarning: false,
    children: null,
    error: null,
    warning: null,
    label: null,
    htmlFor: null,
    id: null,
    helpText: null,
    underHelpText: null,
    showErrorAbove: false
  }
});
